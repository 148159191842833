
import { Options, Prop, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { namespace } from "s-vuex-class";

const LoadAccountModule = namespace("load-account");
const EducationEventsModule = namespace("education-events");

@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },
})
export default class AccountLoaded extends Vue {
  @Prop()
  public studentEducations!: any;

  @LoadAccountModule.Action("loadBalance")
  public loadBalanceAction: any;

  @LoadAccountModule.Getter("getBalanceSuccess")
  public balanceSuccess: any;

  @LoadAccountModule.Getter("getBalanceItem")
  public balance: any;

  @LoadAccountModule.Getter("getSelectedEducation")
  public selectedEducation: any;

  @EducationEventsModule.Action("findAll")
  public findEducationEventAction: any;

  public mounted() {
    this.loadBalance();
  }

  private loadBalance(): void {
    if (!this.selectedEducation || !this.selectedEducation.id) {
      return;
    }
    this.loadBalanceAction(this.selectedEducation);
    this.loadEducationEvents();
  }

  private loadEducationEvents(): void {
    this.findEducationEventAction({
      resource: "education-events",
      extraPath: "student/current",
      hideSuccessMessage: true,
    });
  }

  private onLoadAgainClick(): void {
    this.$emit("onLoadAgain");
  }

  private onCollectMoneyClick(): void {
    this.$emit("onCollectMoney");
  }
}
