import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "placeholder", "id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    value: _ctx.modelValue,
    placeholder: _ctx.placeholder,
    id: _ctx.id,
    ref: "flatpickrRef"
  }, null, 8, _hoisted_1))
}