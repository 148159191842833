import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white radius-10 text-center load-account-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_load_account_form = _resolveComponent("load-account-form")!
  const _component_account_loaded = _resolveComponent("account-loaded")!
  const _component_collect_money = _resolveComponent("collect-money")!
  const _component_money_collected = _resolveComponent("money-collected")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showView === 'load-account-form')
      ? (_openBlock(), _createBlock(_component_load_account_form, {
          key: 0,
          "student-educations": _ctx.studentEducations,
          onOnLoadAccount: _ctx.onLoadAccount,
          onOnCollectMoney: _ctx.showCollectMoneyView
        }, null, 8, ["student-educations", "onOnLoadAccount", "onOnCollectMoney"]))
      : _createCommentVNode("", true),
    (_ctx.showView === 'account-loaded')
      ? (_openBlock(), _createBlock(_component_account_loaded, {
          key: 1,
          balance: _ctx.balance,
          "student-educations": _ctx.studentEducations,
          onOnLoadAgain: _ctx.showLoadAccountView,
          onOnCollectMoney: _ctx.showCollectMoneyView
        }, null, 8, ["balance", "student-educations", "onOnLoadAgain", "onOnCollectMoney"]))
      : _createCommentVNode("", true),
    (_ctx.showView === 'collect-money')
      ? (_openBlock(), _createBlock(_component_collect_money, {
          key: 2,
          balance: _ctx.balance,
          "student-educations": _ctx.studentEducations,
          onOnCollectClick: _ctx.onCollect
        }, null, 8, ["balance", "student-educations", "onOnCollectClick"]))
      : _createCommentVNode("", true),
    (_ctx.showView === 'money-collected')
      ? (_openBlock(), _createBlock(_component_money_collected, {
          key: 3,
          balance: _ctx.balance,
          "student-educations": _ctx.studentEducations,
          onOnLoadAgain: _ctx.showLoadAccountView,
          onOnCollectMoney: _ctx.showCollectMoneyView
        }, null, 8, ["balance", "student-educations", "onOnLoadAgain", "onOnCollectMoney"]))
      : _createCommentVNode("", true)
  ]))
}