
import DefaultLayout from "@/layout/DefaultLayout.vue";
import { Options, Watch } from "vue-property-decorator";
import AccountList from "@/components/Pages/Account/AccountList.vue";
import LoadAccountModal from "@/components/Pages/Home/Account/LoadAccount/LoadAccountModal.vue";
import { namespace } from "s-vuex-class";
import StudentEducationsStoreMixin from "@/mixins/StudentEducations/StudentEducationsStoreMixin";
import { mixins } from "vue-class-component";
import isUndefined from "lodash/isUndefined";
import ModalMixin from "@/mixins/ModalMixin";

const BalanceModule = namespace("balance");
const StudentEducationModule = namespace("student-education");
const LoadAccountModule = namespace("load-account");

@Options({
  components: { LoadAccountModal, AccountList, DefaultLayout },
})
export default class TopUpAccount extends mixins(StudentEducationsStoreMixin, ModalMixin) {
  public name = "TopUpAccount";

  private balance = 0;

  @BalanceModule.Getter("getDataItem")
  public balanceItem: any;

  @BalanceModule.Getter("isPreview")
  public preview: any;

  @StudentEducationModule.Getter("getSelectedAccountStudentEducation")
  public selectedEducation: any;

  @LoadAccountModule.Getter("getSelectedEducation")
  public modalSelectedEducation: any;

  private get livePayStudentEducations(): Array<any> {
    return this.studentEducations.filter((se: any) => se.activeLivePayMandate && se.activeLivePayMandateId);
  }

  @Watch("balanceItem", { immediate: true, deep: true })
  private onBalanceItemChange(balanceItem: any): void {
    if (balanceItem && balanceItem.saldo !== null && !isUndefined(balanceItem.saldo)) {
      this.balance = balanceItem.saldo;
    }
  }
}
