import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadAccountModal = _resolveComponent("LoadAccountModal")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_AccountList = _resolveComponent("AccountList")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, { "show-back-button": true }, {
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, {
            cols: "12",
            xl: "4",
            class: "mb-3 mb-xl-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_LoadAccountModal, {
                balance: _ctx.balance,
                "student-educations": _ctx.livePayStudentEducations
              }, null, 8, ["balance", "student-educations"])
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            cols: "12",
            xl: "8"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AccountList)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}