
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import LoadAccountBtn from "@/components/Pages/Home/Account/LoadAccount/LoadAccountBtn.vue";
import Validation from "@/components/Validation.vue";
import FaceConfused from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceConfused.vue";
import FaceSmileWink from "@/components/Pages/Home/Account/LoadAccount/Emojies/FaceSmileWink.vue";
import { namespace } from "s-vuex-class";
import useVuelidate from "@vuelidate/core";
import { email, minValue, required } from "@vuelidate/validators";

const CollectMoneyModule = namespace("collect-money");

@Options({
  components: {
    FaceSmileWink,
    FaceConfused,
    LoadAccountBtn,
    EducationTabs,
    Validation,
  },
  validations: {
    amountInEur: { required, minValue: minValue(1) },
    emailAddress: { required, email },
  },
})
export default class CollectMoney extends Vue {
  public v$ = useVuelidate();

  @Prop()
  public studentEducations!: any;

  @CollectMoneyModule.Action("setSelectedEducation")
  public selectEducationAction: any;

  @CollectMoneyModule.Action("reset")
  public resetAction: any;

  @CollectMoneyModule.Action("sendThirdPartyPaymentEmail")
  public sendThirdPartyPaymentEmailAction: any;

  @CollectMoneyModule.Getter("getSelectedEducation")
  public selectedEducation: any;

  @CollectMoneyModule.Getter("getSendThirdPartyPaymentEmailSuccess")
  public sendThirdPartyPaymentEmailSuccess: any;

  @CollectMoneyModule.Getter("getIsSendThirdPartyPaymentEmailLoading")
  public loading: any;

  private amountInEur: number | null = null;
  private emailAddress: string | null = null;

  private selectedEducationIndex = 0;

  public created() {
    if (this.studentEducations && this.selectedEducation) {
      this.selectedEducationIndex = this.studentEducations.findIndex((ed: any) => ed.id == this.selectedEducation?.id);
      this.selectedEducationIndex = this.selectedEducationIndex == -1 ? 0 : this.selectedEducationIndex;
    }
    this.resetAction();
  }

  public beforeUnmount(): void {
    this.resetAction();
  }

  private onEducationClassChanged(educationClass: any): void {
    if (educationClass && educationClass.id) {
      this.selectEducationAction(educationClass);
    }
  }

  private sendThirdPartyPaymentEmail(): void {
    this.sendThirdPartyPaymentEmailAction({
      studentEducationId: this.selectedEducation.id,
      amountInEur: this.amountInEur,
      emailAddress: this.emailAddress,
    });
  }

  protected validate(): boolean {
    //@ts-ignore
    this.v$.$touch();

    //@ts-ignore
    return !this.v$.$invalid;
  }

  private onCollectClick(): void {
    if (!this.validate()) {
      return;
    }
    this.sendThirdPartyPaymentEmail();
  }

  @Watch("sendThirdPartyPaymentEmailSuccess", { immediate: true, deep: true })
  private onSendThirdPartyPaymentEmailSuccessChange(sendThirdPartyPaymentEmailSuccess: any): void {
    if (true === sendThirdPartyPaymentEmailSuccess) {
      this.$emit("onCollectClick", {
        studentEducationId: this.selectedEducation.id,
        amountInEur: this.amountInEur,
        emailAddress: this.emailAddress,
      });
    }
  }
}
